import { Flex, Spacer } from '@chakra-ui/react'
import {
  IoMdCube,
  IoMdExpand,
  IoMdImages,
  IoMdPerson,
  IoMdPricetags,
  IoMdSearch,
} from 'react-icons/io'
import { useGetOriginalCollectionsForOrgLazyQuery } from '../../generated/graphql'
import { getOrgData } from '../../hooks/id_token_claims'
import {
  containsFilterByType,
  FILTER_VALUE_TO_DISPLAY,
  genReportFilter,
  MATCH_TYPE_FILTER_VALUE_TO_DISPLAY,
  ReportFilter,
} from '../../hooks/report_table_filters'
import { ReportFilterType } from '../../utils/constants'
import {
  getSortTypes,
  sortingMechanismToDisplay,
} from '../../utils/reports/report_sorting_mechanism'
import SortDropdown from '../sort_dropdown'
import FilterDropdown from '../tables/filter_dropdown'
import SearchFilterToken from './search_filter_token'
import { useSelectedReportFilters, useSelectedReportStatus } from '../../pages/reports'
import { useEffect } from 'react'
import DateFilterDropdown from './date_filter_dropdown/date_filter_dropdown'
import { FilterBarStyle } from '../web2/spoof_reports_table_filter_bar'
import { formatSnakeStringWithCapitalization } from '../../utils/string_utils'

const matchTypes = [
  genReportFilter(ReportFilterType.MatchType, 'Image'),
  genReportFilter(ReportFilterType.MatchType, 'Keyword'),
  genReportFilter(ReportFilterType.MatchType, 'IP'),
]

const tagValues = ['DNS Providers'].map((tag) =>
  genReportFilter(ReportFilterType.Tag, tag),
)

const isOrgWhitelistedForAssignedView = (orgId) => {
  return orgId == '54e61419-d81a-4d9b-9110-b730f7de5eb7'
}

const ReportsTableFilterBar = ({ style = FilterBarStyle.HORIZONTAL }) => {
  const [orgData] = getOrgData()
  const orgID = orgData?.id
  const [selectedReportStatus, _] = useSelectedReportStatus()
  const [selectedReportFilters, setSelectedReportFilters] = useSelectedReportFilters()

  const [getOriginalCollectionsForOrg, { data: data }] =
    useGetOriginalCollectionsForOrgLazyQuery({
      variables: {
        organizationID: orgID,
      },
    })

  useEffect(() => {
    if (orgData?.is_nft_detection_enabled) {
      getOriginalCollectionsForOrg()
    }
  }, [orgData])
  const brandValues = data?.organizations?.[0]?.asset_collections?.map(
    (assetCollection) =>
      genReportFilter(
        ReportFilterType.Brand,
        assetCollection.collection.collection_slug,
        assetCollection.collection.image_url,
      ),
  )
  return (
    <Flex
      align="left"
      flexDirection={style == FilterBarStyle.VERTICAL ? 'column' : 'row'}
    >
      {isOrgWhitelistedForAssignedView(orgID) && (
        <Flex marginRight="8px">
          <FilterDropdown
            dropdownIcon={<IoMdPerson size="16" />}
            filterOptions={
              !orgData
                ? [genReportFilter(ReportFilterType.ReportOwner, 'Me')]
                : orgData.users.map((user) =>
                    genReportFilter(ReportFilterType.ReportOwner, user.name),
                  )
            }
            filterType={ReportFilterType.ReportOwner}
            mb={style == FilterBarStyle.VERTICAL ? '4px' : null}
            textMaxWidth={null}
            valueToDisplayFunction={(value) => FILTER_VALUE_TO_DISPLAY[value]}
          />
        </Flex>
      )}

      <DateFilterDropdown
        marginRight="8px"
        mb={style == FilterBarStyle.VERTICAL ? '4px' : null}
        w="140px"
      />

      <FilterDropdown
        dropdownIcon={<IoMdImages size="18" />}
        filterOptions={brandValues}
        filterType={ReportFilterType.Brand}
        marginRight="8px"
        mb={style == FilterBarStyle.VERTICAL ? '4px' : null}
        textMaxWidth="110px"
        valueToDisplayFunction={formatSnakeStringWithCapitalization}
      />

      <FilterDropdown
        dropdownIcon={<IoMdExpand size="18" />}
        filterOptions={matchTypes}
        filterType={ReportFilterType.MatchType}
        marginRight="8px"
        mb={style == FilterBarStyle.VERTICAL ? '4px' : null}
        textMaxWidth="110px"
        valueToDisplayFunction={(value) =>
          MATCH_TYPE_FILTER_VALUE_TO_DISPLAY[value] ?? value
        }
      />

      <FilterDropdown
        dropdownIcon={<IoMdCube size="16" />}
        filterOptions={
          [
            {
              filterType: ReportFilterType.Chain,
              value: 'eth',
            },
            {
              filterType: ReportFilterType.Chain,
              value: 'matic',
            },
            {
              filterType: ReportFilterType.Chain,
              value: 'flow',
            },
            {
              filterType: ReportFilterType.Chain,
              value: 'sol',
            },
            genReportFilter(ReportFilterType.Chain, 'klay'),
            genReportFilter(ReportFilterType.Chain, 'optimism'),
          ] as ReportFilter[]
        }
        filterType={ReportFilterType.Chain}
        marginRight="8px"
        mb={style == FilterBarStyle.VERTICAL ? '4px' : null}
        textMaxWidth={null}
        valueToDisplayFunction={(value) => value}
      />

      <FilterDropdown
        dropdownIcon={<IoMdPricetags size="18" />}
        filterOptions={tagValues}
        filterType={ReportFilterType.Tag}
        marginRight="8px"
        mb={style == FilterBarStyle.VERTICAL ? '4px' : null}
        textMaxWidth="110px"
        valueToDisplayFunction={formatSnakeStringWithCapitalization}
      />

      {containsFilterByType(ReportFilterType.Search, selectedReportFilters) && (
        <Flex marginRight="8px" mb={style == FilterBarStyle.VERTICAL ? '4px' : null}>
          <SearchFilterToken
            dropdownIcon={<IoMdSearch size="16" />}
            filterType={ReportFilterType.Search}
            selectedReportFilters={selectedReportFilters}
            setSelectedReportFilters={setSelectedReportFilters}
          />
        </Flex>
      )}

      <Spacer />

      <SortDropdown
        sortToDisplay={sortingMechanismToDisplay}
        supportedSortTypes={getSortTypes(selectedReportStatus)}
      />
    </Flex>
  )
}

export default ReportsTableFilterBar
